export const environment = {
    production: true,
    hmr: false,
    debug: false,
    client: 'Mini-US',
    hostName: 'analytics.minidealerprograms.com',
    environmentName: 'production',
    appName: 'Mini-US Digital Analytics',
    printUser: 'print@shiftdigital.com',
    printPassword: 'ShiftDigital#1',
    ssoPassword: 'ShiftDigital#123',
    applicationInsights: {
      instrumentationKey: '3a83451a-cfde-4c3c-a9dd-fd17fa592374'
    },
    defaultLocale: 'en',
    availableLocales: ['en'],
    timeZone: 'Eastern Standard Time',
    showLocaleSelector: 'false',
    numberOfMonthsToShowDefault: 12,
    numberOfQuartersToShowDefault: 8,
    defaultOrgLevelTypeIds: [1],
    closeRateLookbackMonths: 3,
    defaultChartColors: ['#384752', '#52616C', '#05141F', '#D9D9D9', '#CCCCCC'],
    baseApiUri: 'https://mini-us-analytics-api-prod.azurewebsites.net/api',
    authUri: 'https://mini-us-analytics-api-prod.azurewebsites.net/token',
    baseV5ApiUri: 'https://minius-analytics-apiv5-prod.azurewebsites.net/api',
    retailerToolsBaseUri: '',
    retailerToolsIncludeAuth: false,
    mysteryShopBaseUri: '',
    loginRedirectUrl: '',
    logoutRedirectUrl: '',
    favIconPath: '/assets/icons/clients/mini-us/favicon.ico',
    sidebarDefaultOpen: false,
    useV5WebsiteOverview: false,
    v5Reports: []
  };
